<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/finance/home_background.jpg')"
      class="white--text"
      gradient="to right, rgba(0, 0, 0, .8), rgba(89, 89, 88, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="Bienvenue chez Impôt AD" />

          <base-body>
            Impôt AD est une entreprise spécialisée dans la production de déclarations de revenus des particuliers et services comptables connexes. Candidat à la profession de CPA, je suis passionné par tout ce qui touche les domaines de la comptabilité, la fiscalité et les finances.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn to="services">
              Services offerts
            </base-btn>

            <span class="font-weight-bold ml-6 mr-4 my-4">-</span>

            <base-btn
              :ripple="false"
              to="aide-memoire"
              class="pa-1"
              height="auto"
              text
            >
              Aide-mémoire
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh';

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
